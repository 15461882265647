import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import SEO from 'src/components/SEO';
import IndexPage from 'src/components/pages/IndexPage';

const Home = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <>
      <SEO title={frontmatter.title} />
      <IndexPage
        hero={frontmatter.hero}
        businessFeature={frontmatter.businessFeature}
        cards={frontmatter.cards}
      />
    </>
  );
};

Home.propTypes = {
  data: PropTypes.object.isRequired
};

export default Home;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        hero {
          heading
          subheading
        }
        businessFeature {
          heading
          text
        }
        cards {
          logo {
            imgSrc {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED)
              }
            }
            alt
          }
          text
        }
      }
    }
  }
`;
