import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

import BaseHeading from 'src/components/Heading';
import Card from 'src/components/Card';
import Image from 'src/components/Image';

const Hero = tw.section`bg-navy px-4 py-16`;
const Heading = tw(
  BaseHeading
)`text-3xl mx-auto leading-12 sm:text-4xl sm:leading-12 md:w-160`;
const Subheading = tw(Heading)`text-white`;
const CenteredHeading = tw(Heading)`text-center mb-7 sm:w-120`;
const Paragraph = tw.p`mx-auto sm:w-140`;
const Empower = tw.section`bg-gray-200 px-4 pt-28 pb-48 sm:pt-56 sm:pb-96`;
const CardContainer = tw.section`flex flex-wrap space-y-4 justify-center mt-44 mb-56 px-4 md:space-y-0 md:space-x-4 md:flex-nowrap`;
const CardSVGContainer = tw.div`text-center`;

const IndexPage = ({ hero, businessFeature, cards }) => (
  <>
    <Hero>
      <Heading>{hero.heading}</Heading>
      <Subheading>{hero.subheading}</Subheading>
    </Hero>
    <Empower>
      <CenteredHeading>{businessFeature.heading}</CenteredHeading>
      <Paragraph>{businessFeature.text}</Paragraph>
    </Empower>
    <CardContainer>
      {cards.map((cardInfo, index) => (
        <Card key={index}>
          <CardSVGContainer>svg icon</CardSVGContainer>
          <p>{cardInfo.text}</p>
          <CardSVGContainer>
            <Image src={cardInfo.logo.imgSrc} alt={cardInfo.logo.alt} />
          </CardSVGContainer>
        </Card>
      ))}
    </CardContainer>
  </>
);

IndexPage.propTypes = {
  hero: PropTypes.object.isRequired,
  businessFeature: PropTypes.object.isRequired,
  cards: PropTypes.array.isRequired
};

export default IndexPage;
