import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import tw from 'twin.macro';

const Img = tw.img`block`;

const Image = ({ src, alt, ...props }) => {
  if (typeof src === 'object') {
    return <GatsbyImage image={getImage(src)} alt={alt} {...props} />;
  }

  return <Img src={src} alt={alt} {...props} />;
};

Image.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  alt: PropTypes.string.isRequired
};

export default Image;
